.parceiros_wrapper {
  margin-top: 200px;
  margin-bottom: 200px;
}

.parceiros_title {
  font-size: 78px;
  font-weight: 400;
  text-align: center;
}

.parceiros_card_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px;
}

.parceiros_card {
  margin-left: 50px;
  margin-right: 50px;
}

.parceiros_bg {
  position: absolute;
  z-index: -1;
  right: 0;
  margin-top: -100px; 
}

.parceiros_bg_mobile {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}

#parceiros_bg_mobile_right {
  right: 0;
  margin-top: 250px;
  margin-right: 20px;
}

#parceiros_bg_mobile_left {
  margin-top: -50px;
  margin-left: 20px;
}

@media (max-width: 1500px), (-webkit-min-device-pixel-ratio: 1.25) {
  .parceiros_title {
    font-size: 54px;
  }
}

@media (max-width: 545px) {

  .parceiros_wrapper {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 40px;
  }
  .parceiros_title {
    font-size: 36px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;    
  }

  .parceiros_bg {
    visibility: hidden;
  }

  .parceiros_bg_mobile {
    visibility: visible;
  }

  .parceiros_card_wrapper {
    margin: 0;
    flex-wrap: wrap;
  }

  .parceiros_card {
    min-width: 120px;
    width: 120px;
    height: auto;
    margin: 30px;
  }
}