.atuacao_wrapper {
  padding-top: 100px;
}
.atuacao_title {
  padding-left: 2em;
  font-size: 78px;
  font-weight: 400;
}

.container-with-dots {
  padding-top: 100px;
  padding-bottom: 100px;
}

.iconButton {
  cursor: pointer !important;
  position: absolute !important;
  top: 0 !important;
}
.prevButton {
  color: var(--primary_gray);
}

.atuacao_carousel_wrapper {
  position: relative;
  margin-bottom: 100px;
}

.react-multi-carousel-list {
  position: unset !important;
}

.react-multi-carousel-item {
  display: flex !important;
  justify-content: center;
}

.carousel_card {
  width: 380px;
  height: 250px;  
  border-radius: 10px;
  background: var(--primary_gray);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  padding: 20px;

  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.20000000298023224px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  svg {
    width: 100px;
    height: 80px;
    min-width: 100px;
    min-height: 80px;
  }

  .atuacao_carousel_card_text {
    font-size: 20px;
    text-align: justify;
  }
}

#carousel_card_04 {
  h4 {
    margin-bottom: 0;
  }
}

@media (max-width: 1500px), (-webkit-min-device-pixel-ratio: 1.25) {
  .atuacao_title {
    font-size: 54px;
  }

  .carousel_card {
    width: 300px;
    height: 200px;

    h4 {
      font-size: 20px;
    }
    .atuacao_carousel_card_text {
      font-size: 16px;
    }

    svg {
      height: 60px;
      min-height: 60px;
    }
  }
}

@media (max-width: 1500px) {
  .carousel_card {
    width: 280px;
    height: 200px;

    h4 {
      font-size: 18px;
    }
    .atuacao_carousel_card_text {
      font-size: 15px;
    }

    svg {
      height: 60px;
      min-height: 60px;
    }
  }

}
@media (max-width: 545px) {
  .atuacao_wrapper {
    padding-top: 0px;
  }
  .atuacao_title {
    font-size: 36px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    padding-top: 30px;
    padding-left: 0;
    text-align: center;
  }

  .iconButton {
    visibility: hidden;
  }

  .atuacao_carousel_wrapper {
    margin-bottom: 0;
  }

  .carousel_card {
    width: 300px;
    height: 200px;

    h4 {
      font-size: 20px;
    }
    .atuacao_carousel_card_text {
      font-size: 16px;
    }

    svg {
      height: 60px;
      min-height: 60px;
    }
  }

  #carousel_card_04 {
    h4 {
      margin-top: 10px;
    }
  }
}
