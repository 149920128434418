.nav_wrapper {
  display: flex;
  width: 100vw;
  height: 60px;
  background-color: transparent;
  position: absolute;
}
.nav_wrapper_mobile {
  display: none;
}

.nav_container {
  display: flex;
  width: 100vw;
  height: 150px;
  justify-content: space-between;
  align-items: center;
  padding-left: 12em;
  padding-right: 12em;
}

.nav_menu {
  padding-right: 3em;
}
.nav_logo {
  width: 177px;
  height: auto;
  padding-left: 5em;
}

.nav_link {
  padding: 20px;
  text-decoration: none;
  color: var(--primary_white);
  font-size: 1.17em;
  cursor: pointer;
}

@media (max-width: 545px) {
  .nav_wrapper {
    display: none;
  }
  .nav_wrapper_mobile {
    display: block;
  }

  .nav_logo {
    width: 127px;
    padding-left: 30px;
  }
  .MuiMenu-root {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .MuiIconButton-root {
    margin-right: 20px !important;
  }

  .MuiMenu-paper {    
    background-color: var(--mobile_menu_bg) !important;
    color: var(--primary_white) !important;
    width: 90vw;
    height: 50vh;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 90vw !important;
    left: auto !important;
    top: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MuiMenu-list {
    display: flex;
    flex-direction: column;   
    width: 100%;

    li {
      display: flex;
      justify-content: center;
      height: 60px;

      font-family: Overpass;
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.20000000298023224px;    
    }
  }
}
