.contato_wrapper {
  padding-top: 50px;
  padding-bottom: 100px;
}

.MuiButtonBase-root {
  border-radius: 50% !important;
  color: var(--primary_gray) !important;
}

.MuiButtonBase-root:hover {
  background-color: var(--primary_white) !important;
}

.contato_voltar_button {
  position: absolute;
  right: 50px;
  margin-top: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--primary_black) !important;
  cursor: pointer;
  border-radius: 50%;

  svg {
    width: 100px;
    height: auto;
  }
  p {
    font-family: Overpass;
    text-transform: none;
    display: none;
    padding-right: 10px;
    color: var(--primary_black);
  }
}


.contato_detail {
  position: absolute;
  z-index: -1;
  left: 0;
  margin-bottom: -50px;
}

.contato_title {
  font-size: 78px;
  font-weight: 400;
  padding-bottom: 50px;
}

.contato_card_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 300px;
}

.contato_card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contato_form_container {
  height: 669px;
  width: 643px;
  border: 1px;
  border: 1px solid var(--primary_gray);
  border-radius: 20px;
  box-shadow: 0px 13px 19px 0px #00000012;
  background-color: white;
}

.contato_form {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-family: Overpass;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.10000000149011612px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
  }
}

.contato_input_field {
  width: 100%;
  height: 58px;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 50px;
  border: 2px solid var(--input_border_color);
  background-color: var(--input_bg_color);

  padding-left: 20px;
  font-family: Overpass;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  color: var(--input_color);
}

.contato_input_field::placeholder {
  color: var(--input_color);
}

.contato_textarea {
  width: 550px;
  height: 193px;
  box-sizing: border-box;
  resize: none;
  margin-top: 8px;
  border: 2px solid var(--input_border_color);
  border-radius: 20px;
  background-color: var(--input_bg_color);

  //text
  padding: 20px;
  font-family: Overpass;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  color: var(--input_color);
}

.contato_textarea::placeholder {
  color: var(--input_color);
}

.contato_submit_button {
  align-self: center;
  width: 200px;
  height: 56px;
  border: none;
  border-radius: 35px;
  background-color: var(--form_button_color);
  cursor: pointer;
  //text
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  transition: all 0.3s;
}

.contato_submit_button:hover {
  background-color: var(--bg_card_yellow);
}

#confirmation_message {
  visibility: hidden;
  margin-top: 30px;

  //text
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  color: var(--form_button_color);
}

@media (max-width: 1500px), (-webkit-min-device-pixel-ratio: 1.25) {
  .contato_wrapper {
    padding-bottom: 0;
    padding-top: 10px;
  }
  .contato_title {
    font-size: 54px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .contato_detail {
    width: 500px;
  }

  .contato_card_wrapper {
    align-items: center;
    padding: 0;
  }

  .contato_form_container {
    width: 345px;
    height: auto;
  }

  .contato_textarea {
    width: 292px;
    height: 90px;
    font-size: 15px;
  }

  .contato_form {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;

    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.10000000149011612px;
      margin: 0;
    }
  }

  .contato_input_field {
    height: 38px;
    font-size: 15px;
  }

  #confirmation_message {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
  }

  .contato_voltar_button {
    padding: 10px;
    position: relative;
    margin-top: 0;
    right: 20px;
    align-self: flex-end;
    svg {
      width: 72px;
    }
    p {
      display: none;
    }
  }

  .contato_submit_button {
    width: 107px;
    height: 36px;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
  }
}

@media (max-width: 545px) {
  .contato_wrapper {
    padding-bottom: 0;
    padding-top: 10px;
  }
  .contato_title {
    font-size: 36px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .contato_detail {
    display: none;
  }

  .contato_card_wrapper {
    align-items: center;
    padding: 0;
  }

  .contato_form_container {
    width: 345px;
    height: auto;
  }

  .contato_textarea {
    width: 292px;
    height: 90px;
    font-size: 15px;
  }

  .contato_form {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;

    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.10000000149011612px;
      margin: 0;
    }
  }

  .contato_input_field {
    height: 38px;
    font-size: 15px;
  }

  #confirmation_message {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
  }

  .MuiButtonBase-root {
    border-radius: 20px !important;
  }
  .contato_voltar_button {
    padding: 10px;
    position: relative;
    margin-top: 0;
    right: 20px;
    align-self: flex-end;
    svg {
      width: 72px;
    }
    p {
      display: flex;
    }
  }

  .contato_submit_button {
    width: 107px;
    height: 36px;

    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
  }
}
