.clientes_wrapper {
  margin-bottom: 100px;
}
.clientes_title {
  font-size: 78px;
  font-weight: 400;
  text-align: center;
}

.clientes_card_wrapper {
  margin: 80px;
}

.clientes_card_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.clientes_card_border {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 200px;
  border: 2px solid var(--primary_gray);
  background-color: white;
}

.clientes_card {
  width: 300px;
  height: auto;
}

.clientes_bg {
  position: absolute;
  z-index: -1;
  margin-top: -240px;
  left: 0;
}

.clientes_bg_mobile {
  position: absolute;
  z-index: -1;
  right: 0;
  visibility: hidden;
  margin-top: -100px;
  margin-right: 30px;
}

@media (max-width: 1500px), (-webkit-min-device-pixel-ratio: 1.25) {
  .clientes_title {
    font-size: 54px;
  }
}

@media (max-width: 545px) {
  .clientes_title {
    font-size: 36px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    padding-top: 30px;    
  }

  .clientes_card_wrapper {
    margin: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;    
  }

  .clientes_card_container {
    flex-direction: column;
  }
  .clientes_card {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .clientes_card_border {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;    
    height: auto;
    border: none;
    background-color: transparent;
  }

  .clientes_bg {
    visibility: hidden;
  }
  .clientes_bg_mobile {
    visibility: visible;
  }
}
