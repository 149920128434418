.home_wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  background: url("../../assets/bg_home.svg") no-repeat;
  background-size: cover;
  background-position: bottom;
}

.home_container {
  margin-left: 8em;
  margin-right: 8em;
}

.logo_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home_logo {
  width: 617px;
  height: auto;
}

.home_logo_text {
  font-size: 28px;
  font-weight: 400;
  color: var(--primary_white);
}

@media (max-width: 545px) {
  .home_wrapper {
    justify-content: center;
    align-items: flex-start;
    background-position: 70%;    
    height: 791px;
  }
  .home_container {
    margin-left: 0em;
    margin-right: 0em;
    margin-top: 200px;  
  }

  .home_logo {
    width: 337.95px;
  }

  .home_logo_text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
  }
}
