.footer_wrapper {
  display: flex;
  justify-content: space-around;
  background-color: var(--form_button_color);
  padding: 120px;
  padding-left: 200px;
}

.footer_logo {
  min-width: 421px;
  min-height: 111.64px;
}

.footer_contatos_wrapper {
  margin-left: 50px;
  margin-right: 50px;

  h4 {
    font-family: Overpass;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    margin-top: 0;
  }
}

#footer_instagram_icon {
  padding-top: 2px;
  width: 37px !important;
  height: auto !important;
  color: #01070c;
}
.footer_contatos_card {
  a {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-decoration: none;
    color: unset;
  }
}

.footer_contatos_field {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.footer_icon {
  padding-right: 20px;
}

.socialmedia_icon {
  padding-right: 20px;
}

.footer_socialmedia_card {
  padding-top: 30px;
}
.socialmedia_icon_wrapper {
  display: flex;
}

.footer_location_wrapper {
  display: flex;
  flex-direction: column;

  a {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-decoration: none;
    color: unset;
  }
}

.footer_end_bar {
  width: 100vw;
  height: 23px;
  background-color: var(--footer_end_bar);
}

@media (max-width: 1500px), (-webkit-min-device-pixel-ratio: 1.25) {
  .footer_wrapper {
    padding: 50px;
  }
  .footer_logo {
    width: 160px;
    min-width: 250px;
    min-height: auto;
  }

  .footer_contatos_wrapper {
    h4 {
      font-size: 20px;
    }
  }
}

@media (max-width: 545px) {
  .footer_wrapper {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 30px;
  }

  .footer_logo {
    width: 214px;
    min-width: 214px;
    min-height: 56px;
  }
  .footer_contatos_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-family: Overpass;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0.20000000298023224px;
      text-align: center;
    }
  }

  .footer_contatos_card {
    h4 {
      text-align: center;
    }
  }

  .footer_location_wrapper {
    align-items: center;
  }

  .socialmedia_icon_wrapper {
    justify-content: center;
  }
  .socialmedia_icon {
    padding-right: 10px;
    padding-left: 10px;
  }
}
