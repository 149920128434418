:root {
  --primary_white: #f9f9f9;
  --primary_black: #01070c;
  --primary_gray: #d3d3d3;
  --bg_card_yellow: #fded6a;
  --input_color: #18171d;
  --input_bg_color: #f5f5f5;
  --input_border_color: #e8e8e8;
  --form_button_color: #f9ba0d;
  --mobile_menu_bg: #666666;
  --footer_end_bar: #363636;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary_black);
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-family: "Overpass", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 545px) {
  
}
