.setores_wrapper {
  padding-top: 50px;
}

.setores_mobile {
  display: none;
}

.setores_title {  
  display: flex;
  justify-content: center;
  font-size: 78px;
  font-weight: 400;
}

.setores_carousel_wrapper {
  position: relative;
  margin-bottom: 100px;
}
.setores_icon {  
  z-index: 3;
  color: var(--primary_white);  
}

.setores_card_container {
  display: flex;
}

.setores_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: aliceblue;  
  width: 484px;
  height: 484px;

  h2 {
    margin-top: 5px;
    margin-bottom: 0px;
    height: 68px;
    padding-left: 50px;
    padding-right: 50px;
    font-family: Overpass;
    font-size: 48px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: 0.20000000298023224px;
    text-align: center;
    z-index: 3;
  }
}

.setores_card::before {
  content: "  ";
  width: 484px;
  height: 484px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
}

#setores_card_01 {  
  background: url("../../assets/transportes_card.svg");
  background-size: cover;
}
#setores_card_02 {  
  background-image: url("../../assets/energia_card.svg");
  background-size: cover;
}
#setores_card_03 {  
  background-image: url("../../assets/portos_card.svg");
  background-size: cover;
}
#setores_card_04 {  
  background-image: url("../../assets/aeroportos_card.svg");
  background-size: cover;
}
#setores_card_05 {  
  background-image: url("../../assets/saneamento_card.svg");
  background-size: cover;
}
#setores_card_06 {  
  background-image: url("../../assets/oleo_card.svg");
  background-size: cover;
}
#setores_card_07 {  
  background-image: url("../../assets/telecom_card.svg");
  background-size: cover;
}
#setores_card_08 {  
  background-image: url("../../assets/parques_card.svg");
  background-size: cover;
}
@media (max-width: 1500px), (-webkit-min-device-pixel-ratio: 1.25) {
  .setores_title {
    font-size: 54px;
  }

  .setores_card {    
    width: 300px;
    height: 300px;    
    border-radius: 20px;
    margin: 5px;

    h2 {
      font-size: 18px;  
      line-height: normal; 
      height: auto; 
    }
  }
  .setores_card::before {
    content: "  ";
    width: 300px;
    height: 300px;
  }

  .setores_icon {
    width: 100px;
    height: auto;
    align-self: center;
  }
}

@media (max-width: 545px) {
  .setores_wrapper {
    padding-top: 30px;
  }
  
  .setores_mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
  }

  .setores_carousel_wrapper {
    display: none;
  }

  .setores_title {
    font-size: 36px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.20000000298023224px;
    margin-bottom: 50px;
  }
  
  .setores_card {    
    width: 166px;
    height: 166px;    
    border-radius: 20px;
    margin: 5px;

    h2 {
      font-size: 18px;  
      line-height: normal; 
      height: auto; 
    }
  }
  .setores_card::before {
    content: "  ";
    width: 166px;
    height: 166px;
  }

  .setores_icon {
    width: 60px;
    height: auto;
    align-self: center;
  }
}